<template>
  <v-select
    v-if="items.length >= 2"
    :value="valueData"
    solo
    v-model="valueData"
    ref="select"
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    :menu-props="{
      offsetY: true,
      nudgeBottom: '8px',
      closeOnContentClick: false,
      contentClass: 'custom-dropdown-select custom-dropdown-select--plans',
    }"
    :required="required"
    class="v-select--select-bold filter-select elevation-4"
    :hide-details="showDetails ? false : true"
    :class="`v-input--${size} input-dropdown ${customClasses}`"
    v-on="$listeners"
    :return-object="returnObject"
  >
    <template v-slot:no-data>
      <loader />
    </template>

    <template v-slot:selection="{ item }">
      <div class="d-flex flex-column py-3 w-100">
        <span class="font-weight-bold">{{ splitName(item[itemText])[0] }}</span>
        <span class="font-weight-600 p-4 gray--text text--darken-1 text-truncate" style="max-width: calc(100% - 24px)">
          {{ splitName(item[itemText])[1] }}
        </span>
      </div>
    </template>

    <template v-slot:prepend-inner>
      <slot name="prepend-inner">
        <div class="v-text-field__prefix" v-if="prefix">
          {{ prefix }}
        </div>
      </slot>
    </template>

    <template v-slot:item="{ item, on, attrs }">
      <div class="input-dropdown-item" v-on="on" v-bind="attrs">
        <div class="d-flex flex-column" style="max-width: 100%">
          <span class="font-weight-bold">{{
            splitName(item[itemText])[0]
          }}</span>
          <span
            class="font-weight-600 p-4 gray--text text--darken-1 text-truncate"
          >
            {{ splitName(item[itemText])[1] }}
          </span>
        </div>
        <v-icon class="ml-2 input-dropdown-item__icon">$check</v-icon>
      </div>
    </template>
    <template v-slot:append="">
      <v-icon size="16">$chevrondown</v-icon>
    </template>
  </v-select>
</template>

<script>
import Loader from "./Loader.vue";
export default {
  components: { Loader },
  props: {
    customClasses: {
      type: String,
      default: "",
    },
    block: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    showDetails: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
    },
    itemText: {
      type: String,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
    size: {
      type: String,
      validator: function (value) {
        return ["xlg", "lg", "md", "sm", "xsm"].indexOf(value) !== -1;
      },
      default: "xlg",
    },
    prefix: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    valueData: {
      get: function () {
        return this.value;
      },
      set: function (newData) {
        this.blur();
        this.$emit("update:value", newData);
      },
    },
  },
  methods: {
    blur: function () {
      document.activeElement.blur();
    },
    splitName: function (item) {
      const split = item.split(" - ");
      return [split[0], split[1]];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  max-width: 100%;
}
.v-select::v-deep {
  .v-select__selection--comma {
    white-space: initial !important;
  }
  &.v-select--is-menu-active {
    background-color: map-get($primary, lighten4);
    .v-icon__component {
      color: map-get($primary, darken1) !important;
    }
  }
  .v-menu__content {
    display: none;
  }
  .v-input__slot {
    max-height: unset !important;
    display: flex;
    align-items: center;
    box-shadow: none !important;
  }
}

.input-dropdown {
  &.v-select--is-menu-active {
    .v-input__append-inner {
      .v-icon {
        color: map-get($primary, darken1) !important;
        transform-origin: center;
        transform: rotate(180deg);
      }
    }
  }
}

.v-text-field__prefix {
  margin-top: 2px;
}

.v-list-item {
  max-height: unset;
  &::before {
    display: none;
  }
  .input-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: wrap;
    height: fit-content;
    max-width: calc(100% - 24px);
    .v-icon {
      opacity: 0;
      transition-delay: 0.5s;
      transition: opacity 0.24s ease;
    }
  }
  .input-dropdown-item[aria-selected="true"] {
    font-weight: $font-weight-semibold;

    .v-icon {
      opacity: 1;
    }
  }
  .input-dropdown-item[aria-selected="true"] {
    color: map-get($primary, darken1);

    &::before {
      background-color: transparent;
    }
  }
}
.v-application--is-rtl{
    .v-select__slot{
        .d-flex.flex-column.py-3.w-100{
            text-align: right;
        }
    }
}
</style>