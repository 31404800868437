<template>
  <div>
    <div class="px-6">
      <v-container class="px-0">
        <div
          class="d-flex justify-space-between align-center flex-lg-row flex-row"
          :class="[
            $vuetify.breakpoint.lgAndDown ? 'mt-3 mb-3' : 'mt-7 mb-7',
            $vuetify.breakpoint.xsAndDown ? 'flex-column' : 'flex-row',
          ]"
        >
          <div
            class="w-100 d-flex align-center justify-space-between top-title"
          >
            <h1 class="font-weight-800">
              {{ $t("heading.navbar.tab.myHosting") }}
            </h1>
          </div>
        </div>
      </v-container>
    </div>

    <v-divider
      :class="$vuetify.breakpoint.lgAndDown ? 'mb-3' : 'mb-10'"
    ></v-divider>

    <div class="px-6">
      <v-container class="px-0">
        <v-row
          align="stretch"
          class="mx-0 flex-lg-row flex-column"
          no-gutters
          :class="[$vuetify.breakpoint.mobile ? 'mobile-container' : '']"
        >
          <hosting-pages-sidebar
            v-if="!serviceLoading"
            :services="services"
            :activeService="selectedService"
            @update:activeService="changeService"
          />
            <v-col
              cols="12"
              lg="10"
              :class="[$vuetify.breakpoint.lgAndDown ? `` : 'pl-12']"
            >
              <router-view
                v-if="!serviceLoading"
                :service="selectedService"
                @pass-instance="passNewInstanceData"
              />
            </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import HostingPagesSidebar from "../../components/HostingPagesSidebar.vue";

export default {
  components: {
    HostingPagesSidebar,
  },
  data() {
    return {
      serverAccountsLoading: true,
      serverAccounts: [],
      serverAccount: null,
      hostingAccountId: null,

      serviceLoading: true,
      services: [],
      selectedService: null,
      selectedServiceId: null
    };
  },
  methods: {
    passNewInstanceData(instance) {
      this.hostingAccountId = instance.hostingId;
    },
    changeService: function (item) {
      this.selectedService = item;
    },
    getServiceIdFromQuery: function () {
      if (!this.$route.query.id) {
        return false;
      }

      const id = this.$route.query.id;

      let query = Object.assign({}, this.$route.query);
      delete query.id;
      this.$router.replace({query});
      return id;
    },
  },
  mounted() {
    if (!this.$store.state.home.user.has_hosting_access) {
      this.$router.push({name: "Instances"});
      return;
    }

    const serviceId = this.selectedServiceId
      ? this.selectedServiceId
      : this.getServiceIdFromQuery();

    Api.get("/services")
      .then((response) => {
        const services = response.data.data.map((service) => {
           service.server_accounts = service.server_accounts.map((serverAccount) => {
            if (serverAccount.details && serverAccount.details.homedir) {
              serverAccount.homedir = `${serverAccount.details.homedir}/`;
            }

            if (serverAccount.details && serverAccount.details.mysql_db_prefix) {
              serverAccount.mysql_db_prefix = serverAccount.details.mysql_db_prefix;
            }
            if (serverAccount.main_domain_nameservers) {
              serverAccount.nameservers = serverAccount.main_domain_nameservers || [];
            }

            if (serverAccount.details && serverAccount.details.ip_address) {
              serverAccount.ip_address = serverAccount.details.ip_address || null;
            }
            return serverAccount;
          })
          return service;
        });

        for (const service of services) {
          if (service.status !== "active") {
            continue;
          }
          let hasAnyHostingPrivilege = false;
          for (const priv of service.privileges) {
            if (priv.startsWith("hosting.")) {
              hasAnyHostingPrivilege = true;
              break;
            }
          }
          if (!hasAnyHostingPrivilege) {
            continue;
          }

          service.name = `${service.plan_name} - ${service.domain}`;

          service.getUserPrivileges = (privileges, permission) => {
            if (privileges && privileges.length) {
              return privileges.indexOf(permission) > -1;
            } else {
              return true;
            }
          };

          this.services.push(service);
        }

        if (!serviceId) {
          this.selectedService = this.services[0];
        } else {
          const foundPlan = services.find((service) => {
            return service.id == serviceId;
          });

          if (!foundPlan) {
            this.selectedService = this.services[0];
          } else {
            this.selectedService = foundPlan;
          }
        }
        this.serverAccounts = this.selectedService.server_accounts;
      })
      .catch((error) => {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      })
      .finally(() => {
        this.serviceLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.top-title {
  height: 46px;
}

::v-deep tr {
  transition: background-color 10s ease;
}

::v-deep tr.highlight {
  background-color: var(--v-primary-lighten2) !important;
}
</style>
